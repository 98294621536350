<template>
  <div class="classificationInterestContainer">
    <div class="classificationInterestHeaderContainer">
      <div class="flex" style="margin-bottom: 24px">
        <div class="classificationInterestTitle" style="margin-bottom:0">选择感兴趣的分类（最多同时勾选五个分类）</div>
        <el-popover placement="top-start" trigger="hover">
          <div>{{ $fanyi("名字需要修改，请与业务员进行联系") }}</div>
          <img
              slot="reference"
              src="../../../../../assets/4.png"
              style="width: 20px; margin-left: 10px; cursor: pointer;margin-top: 1px"
          />
        </el-popover>
      </div>
      <div class="interestListContainer">
        <div v-for="(item,index) in interestCategoryList" :key="index" class="interestItemContainer">
          <input v-model="item.is_checked" type="checkbox" @change="categoryChecked(item)"/>
          <div v-if="item.is_custom==0">{{ item.interest_name }}</div>
          <div v-else class="editNameContainer">
            <input v-model="item.interest_name" @blur="submitCategory"/>
            <div class="editIcon" @click="interestCategoryList.splice(index,1);submitCategory();">x</div>
          </div>
          <div v-if="index===interestCategoryList.length-1"
               :style="item.is_custom==0?'margin-left:30px':'margin-left:12px'"
               class="btn" @click="add(1)">{{ $fanyi('追加') }}
          </div>
        </div>
      </div>
    </div>
    <div class="classificationInterestFooterContainer">
      <div class="classificationInterestTitle">选择您想售卖的平台</div>
      <div class="interestListContainer">
        <div v-for="(item,index) in interestPlatformList" :key="index" class="interestItemContainer">
          <input v-model="item.is_checked" type="checkbox" @change="submitPlatform"/>
          <div v-if="item.is_custom==0">{{ item.interest_name }}</div>
          <div v-else class="editNameContainer">
            <input v-model="item.interest_name"/>
            <div class="editIcon" @click="interestPlatformList.splice(index,1);submitPlatform()">x</div>
          </div>
          <div v-if="index===interestPlatformList.length-1"
               :style="item.is_custom==0?'margin-left:30px':'margin-left:12px'"
               class="btn" @click="add(2)">
            {{ $fanyi('追加') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      interestCategoryList: [],
      interestPlatformList: [],
    }
  },
  created() {
    this.getInterestCategoryList();
    this.getInterestPlatformList();
  },
  methods: {
    getInterestCategoryList() {
      this.$api.interestCategoryList().then((res) => {
        res.data.forEach((item) => {
          item.is_checked = item.is_checked == 1;
        })
        this.interestCategoryList = res.data;
      });
    },
    getInterestPlatformList() {
      this.$api.interestPlatformList().then((res) => {
        res.data.forEach((item) => {
          item.is_checked = item.is_checked == 1;
        })
        this.interestPlatformList = res.data;
      });
    },
    add(type) {
      if (type == 1) {
        this.interestCategoryList.push({
          interest_name: "", is_checked: false, is_custom: 1
        })
      } else {
        this.interestPlatformList.push({
          interest_name: "", is_checked: false, is_custom: 1
        })
      }
    },
    categoryChecked(item) {
      let count = 0;
      for (let i = 0; i < this.interestCategoryList.length; i++) {
        if (this.interestCategoryList[i].is_checked) {
          count++
        }
      }
      if (count > 5) {
        this.$nextTick(() => {
          item.is_checked = false;
        })
        this.$message.error('最多同时勾选五个分类!');
      } else {
        this.submitCategory();
      }
    },
    submitCategory() {
      let arr = [];
      let data = {
        interest_type: 1,
        interest_name_list: arr
      }
      this.interestCategoryList.forEach((item) => {
        if (item.interest_name != '') {
          arr.push({
            interest_name: item.interest_name,
            is_checked: item.is_checked == true ? '1' : '0',
            is_custom: item.is_custom
          })
        }
      })
      this.$api.interestSetInterest(data).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.getInterestCategoryList();
      });
    },
    submitPlatform() {
      let arr = [];
      let data = {
        interest_type: 2,
        interest_name_list: arr
      }
      this.interestPlatformList.forEach((item) => {
        if (item.interest_name != '') {
          arr.push({
            interest_name: item.interest_name,
            is_checked: item.is_checked == true ? '1' : '0',
            is_custom: item.is_custom
          })
        }
      })
      this.$api.interestSetInterest(data).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.getInterestPlatformList();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.classificationInterestContainer {
  width: 640px;
  margin-left: -100px;
  margin-bottom: 26px;
  border: 1px solid #DFDFDF;
  border-radius: 3px;
  padding: 24px 30px 0;

  .classificationInterestHeaderContainer {
    padding-bottom: 8px;
    border-bottom: 1px dashed #DFDFDF;
  }

  .classificationInterestTitle {
    font-size: 16px;
    color: #000000;
    margin-bottom: 24px
  }

  .classificationInterestFooterContainer {
    padding: 23px 0 9px;
  }

  .interestListContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .interestItemContainer {
      display: flex;
      align-items: center;
      margin: 0 30px 20px 0;

      input {
        margin-right: 5px;
      }

      .editNameContainer {
        position: relative;

        input {
          width: 100px;
          height: 22px;
          background: #FFFFFF;
          border-radius: 3px;
          border: 1px solid #DFDFDF;
        }
      }

      .btn {
        width: 50px;
        height: 22px;
        background: #B4272B;
        border-radius: 3px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 22px;
      }

      .editIcon {
        width: 16px;
        height: 16px;
        background: #B4272B;
        border-radius: 50%;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        text-align: center;
        line-height: 13px;
        position: absolute;
        right: 0;
        top: -8px;
      }
    }
  }
}
</style>