<template>
  <div style="margin-top: 40px">
    <div class="label">您需要进行验证的邮箱</div>
    <div class="inputContainer">
      {{ $store.state.userInfo.email }}
    </div>
    <div class="label">{{ $fanyi('验证码') }}</div>
    <div class="codeContainer">
      <el-input v-model="value" style="width: 150px;" size="mini"/>
      <el-button type="primary" size="mini" style="margin-left: 10px" v-show="show"
                 @click="getNewEmailVerificationCode">
        {{ $fanyi('获取验证码') }}
      </el-button>
      <el-button type="primary" size="mini" style="margin-left: 10px" v-show="!show">{{ Countdown }} S</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      show: true,
      Countdown: 60,
    }
  },
  methods: {
    getNewEmailVerificationCode() {
      this.show = true;
      this.show = false;
      this.timer = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          this.show = true;
          this.Countdown = 60;
          clearInterval(this.timer);
        }
      }, 1000);
      this.$api.getToolCheckedCode({
        check_number_type: 'email'
      }).then((res) => {

      });
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: #333333;
  font-size: 14px;
  margin-bottom: 9px;
}

.inputContainer {
  height: 30px;
  background: #F9F9F9;
  border: 1px solid #DFDFDF;
  border-radius: 3px;
  line-height: 30px;
  color: #333333;
  font-size: 14px;
  margin-bottom: 20px;
  padding-left: 9px;
}

.codeContainer {
  display: flex;
  align-items: center;

  .el-button {
    min-width: 116px;
  }
}
</style>
